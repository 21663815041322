<template>
  <div class="content">
    <PageHeader title="Short Codes" />
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!shortCodes.length && fetched"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-hashtag font-size-80"></i>
                  <h5 class="card-title m-t-20">
                    {{$t('short-code.none')}} <!-- Nenhum Short Code cadastrado -->
                  </h5>
                </div>
                <table v-if="fetched" class="table">
                  <thead class="bg-light" v-if="shortCodes.length">
                    <tr>
                      <th scope="col">{{$t('sms.short-code.lbl-number')}}</th><!-- Número -->
                      <th scope="col">{{$t('sms.short-code.lbl-nickname')}}</th><!-- Apelido -->
                      <th scope="col">Status</th>
                      <th scope="col">{{$t('sms.short-code.lbl-edit')}}</th><!-- Editar -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="shortCode in shortCodes" :key="shortCode.id">
                      <td data-label="Número">
                        {{ shortCode.name }}
                      </td>
                      <td data-label="Apelido">
                        {{ shortCode.data.alias }}
                      </td>
                      <td data-label="Status">
                        <span
                          v-if="shortCode.data.status == 'active'"
                          class="badge badge-success"
                          >{{$t('sms.short-code.lbl-active')}}</span> <!-- Ativo -->
                      </td>
                      <td data-label="Editar">
                        <router-link :to="`/sms/short-codes/${shortCode.data.id}`">
                          <button class="btn btn-success btn-sm">
                            <i class="fas fa-pencil-alt color-white"></i>
                          </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'ShortCodes',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      shortCodes: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getAccountShortCodes(this.form)
        .then(
          (response) => {
            this.shortCodes = response.data;
            this.pages = response.last_page;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}
.color-white {
  color: #fff;
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
</style>
